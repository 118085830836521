import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";

import Styles from "@/types/styles";

const namespaced = true;

export const state: Styles = {
    client_name: '',
    sidebar_bg_color: '',
    primary_color: '',
    background_color: '',
    secondary_color: '',
    sidebar_text_color: '',
    logo: '',
    login_logo: '',
    tab_logo: '',
    custom_logo: ''
}
export const mutations: MutationTree<Styles> = {

}

export const getters: GetterTree<Styles, RootState> = {
    getStyles(state) {
        return state;
    },
}

export const actions: ActionTree<Styles, RootState> = {
    setTheme({ state }, styles: string) {
        const theme: Styles = JSON.parse(styles);
        state.client_name = theme.client_name;
        state.primary_color = theme.primary_color;
        state.secondary_color = theme.secondary_color;
        state.background_color = theme.background_color;
        state.sidebar_text_color = theme.sidebar_text_color;
        state.logo = theme.logo;
        state.custom_logo = theme.custom_logo || "";
        state.tab_logo = theme.tab_logo;
        state.sidebar_bg_color = theme.sidebar_bg_color;
    }
}

export const styles: Module<Styles, RootState> = {
    namespaced,
    state,
    actions,
    getters,
    mutations
}